import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private swUpdate: SwUpdate,
    private toastController: ToastController
  ) {}

  public ngOnInit(): void {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        this.showUpdateToast();
      });
    }
  }

  private async showUpdateToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'Dostępna jest aktualizacja oprogramowania',
      buttons: [{ text: 'Odśwież', role: 'continue' }],
    });
    await toast.present();
    const { role } = await toast.onDidDismiss();
    if (role === 'continue') {
      window.location.reload();
    }
  }
}
